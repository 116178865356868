import fall25 from './fall25'
import spring25 from './spring25'
import spring26 from './spring26'

const state = {
  currentSite: 'spring26',
  previousSite: 'fall25'
}

const modules = {
  spring25,
  fall25,
  spring26
}

export default {
  state,
  modules,
  namespaced: true
}
