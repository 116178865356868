import { loadView } from '@/helpers/asyncLoaders'

import { beforeEnterMiddleware } from '@/router/navGuards'

import { routes as Spring26 } from '@/router/modules/globalConferences/spring26'
import { routes as Spring25 } from '@/router/modules/globalConferences/spring25'
import { routes as Spring24 } from '@/router/modules/globalConferences/spring24'
import { routes as Spring23 } from '@/router/modules/globalConferences/spring23'
import { routes as Fall23 } from '@/router/modules/globalConferences/fall23'
import { routes as Fall24 } from '@/router/modules/globalConferences/fall24'
import { routes as Fall25 } from '@/router/modules/globalConferences/fall25'

export default {
  path: '/global/conference',
  component: loadView('app/GlobalConference.vue'),
  // Nav Guards
  beforeEnter: (to, from, next) =>
    beforeEnterMiddleware(to, from, next, {
      authenticate: true
    }),
  children: [
    Spring26,
    Spring25,
    Spring24,
    Spring23,
    Fall23,
    Fall24,
    Fall25
  ]
}
